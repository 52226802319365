import { Elm } from "./Main.elm";

const DELAY_BACKGROUND_CHANGE = 10000;

const baseUrl = "https://res.cloudinary.com/sub-zero-hockey/image/upload";
// const effect = "e_cartoonify";
const effect = "e_vectorize";
const transforms = `c_scale,${effect},q_100,w_1920`;
const version = "v1629154222";
const folder = "vegas";

const makeCloudinaryImage = (name) =>
	`${baseUrl}/${transforms}/${version}/${folder}/${name}`;

const IMAGES_BACKGROUND = [
	makeCloudinaryImage("bellagio-fountains-skyline-night.png"),
	makeCloudinaryImage("strip-central-skyline.png"),
	makeCloudinaryImage("strip-south-skyline.png"),
];

(function preload () {
	const images = [];

	IMAGES_BACKGROUND.map((imageUrl, index) => {
		images[index] = new Image();
		images[index].src = imageUrl;
	});
}());

let state = "initial";

const setBackgroundImage = (imageUrl) => {
	document.body.style.backgroundImage = `url(${imageUrl})`;
};

const initBackground = () => {
	let current = 0;

	setBackgroundImage(IMAGES_BACKGROUND[current]);

	setInterval(
		() => {
			current = (current + 1) >= IMAGES_BACKGROUND.length ? 0 : current + 1;
			setBackgroundImage(IMAGES_BACKGROUND[current]);
		},
		DELAY_BACKGROUND_CHANGE,
	);

	state = "loaded";
};


const isReady = ({ readyState }) =>
	readyState === "interactive" || readyState === "complete";

document.onreadystatechange = () => {
	if (isReady(document) && state === "initial") {
		initBackground();
	}
};

Elm.Main.init({ node: document.getElementById("root") });
