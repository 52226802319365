module Main exposing (..)

import Browser
import Browser.Navigation as Nav
import Html exposing
  ( Attribute
  , Html
  , div
  , h1
  , h2
  , main_
  , section
  , text
  )
import Html.Attributes exposing
  ( class
  , style
  )
import Html.Events exposing (onClick)
import Url


-- MAIN


main =
  Browser.application
    { init = init
    , onUrlChange = UrlChanged
    , onUrlRequest = LinkClicked
    , subscriptions = subscriptions
    , update = update
    , view = view
    }


-- MODEL


type alias Model =
  { email : String
  }


init : () -> Url.Url -> Nav.Key -> ( Model, Cmd Msg )
init _ _ _ =
  ( { email = ""
    }
  , Cmd.none
  )


-- UPDATE


type Msg
  = LinkClicked Browser.UrlRequest
  | UrlChanged Url.Url


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
  case msg of
    LinkClicked _ ->
      ( model, Cmd.none )

    UrlChanged _ ->
      ( model, Cmd.none )


-- VIEW


view : Model -> Browser.Document Msg
view _ =
  { title = "Sub-Zero Hockey"
  , body =
      [ viewMain
      ]
  }


viewMain : Html msg
viewMain =
  main_
    []
    [ section [ class "hero" ]
      [ h2 [] [ text "A New Day for Vegas Youth Hockey" ]
      , h1 [] [ text "Sub-Zero Hockey" ]
      , h2 [] [ text "Coming Soon..." ]
      ]
    ]


-- SUBSCRIPTIONS


subscriptions : Model -> Sub Msg
subscriptions _ =
  Sub.none
